
import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { directus } from '../main'

export default defineComponent({
  name: 'NewsDetail',
  components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonSearchbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, },
  data: function() {
    return {
      loading: false,
      error: null,
      news: null,
    }
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return { id };
  },
  mounted() {
    this.error = this.news = null
    this.loading = true
    const url_id = this.$route.params.id

    async function getNews() {
        // ... before fetching items
        return await directus.items('news').read(url_id, {
          fields: ['titel', 'untertitel', 'datum', 'inhalt', 'kategorie.*'],
        });
      }
      getNews().then(news => {
        this.news = news.data;
        this.loading = false;
      }).catch(err => {
        this.error = err.toString();
      })
    },
})
