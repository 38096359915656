<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('news')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('news')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
        <h4>Lädt</h4>
      </div>

      <div v-if="error" class="loading">
        {{ error }}
      </div>

      <div class="news-detail" v-if="news != null">
        <h2>{{ news.titel }}</h2>
        <h4>{{ news.untertitel}}</h4>
        <p>{{ new Date(news.datum).toLocaleDateString('de-DE') }}</p>

        <ion-badge color="primary">{{ news.kategorie.titel }}</ion-badge>
        <div class="news-detail-content" v-html="news.inhalt"></div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { directus } from '../main'

export default defineComponent({
  name: 'NewsDetail',
  components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonSearchbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, },
  data: function() {
    return {
      loading: false,
      error: null,
      news: null,
    }
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    return { id };
  },
  mounted() {
    this.error = this.news = null
    this.loading = true
    const url_id = this.$route.params.id

    async function getNews() {
        // ... before fetching items
        return await directus.items('news').read(url_id, {
          fields: ['titel', 'untertitel', 'datum', 'inhalt', 'kategorie.*'],
        });
      }
      getNews().then(news => {
        this.news = news.data;
        this.loading = false;
      }).catch(err => {
        this.error = err.toString();
      })
    },
})
</script>

<style scoped>
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.news-detail {
  margin: 10px;
}
.news-detail > h4 {
  color: #555555;
}
.news-detail-content {
  margin-top: 10px;
}
</style>